// src/components/blog/admin/BlogPostEditor.tsx
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import supabase from '../../../../Auth/supabase';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { v4 as uuidv4 } from 'uuid';


interface BlogPostForm {
  title: string;
  slug: string;
  content: string;
  excerpt: string;
  author: string;
  featured_image: string | null;
}

const BlogPostEditor: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const reactQuillRef = useRef<any>(null);
  const [form, setForm] = useState<BlogPostForm>({
    title: '',
    slug: '',
    content: '',
    excerpt: '',
    author: '',
    featured_image: null,
  });

  useEffect(() => {
    if (id) {
      fetchPost();
    }
  }, [id]);

  const fetchPost = async () => {
    try {
      const { data, error } = await supabase
        .from('blog_posts')
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;
      if (data) {
        setForm({
          title: data.title,
          slug: data.slug,
          content: data.content,
          excerpt: data.excerpt || '',
          author: data.author || '',
          featured_image: data.featured_image || null,
        });
      }
    } catch (error) {
      console.error('Error fetching post:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
        const currentDate = new Date().toISOString();

      if (id) {
        // Update existing post
        const { error } = await supabase
          .from('blog_posts')
          .update({
            ...form,
            updated_at: currentDate,
          })
          .eq('id', id);

        if (error) throw error;
      } else {
        // Create new post
        const { error } = await supabase.from('blog_posts').insert([
          {
            ...form,
            is_published: false,
            created_at: currentDate,
            updated_at: currentDate,
            published_date: null  // This will be set when post is published
          },
        ]);

        if (error) throw error;
      }

      navigate('/admin/blog');
    } catch (error) {
      console.error('Error saving post:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;
    
    const file = files[0];
    const fileExt = file.name.split('.').pop();
    const fileName = `${uuidv4()}.${fileExt}`;
    const filePath = `blog-images/${fileName}`;
    
    setImageUploading(true);
    
    try {
      const { error: uploadError } = await supabase.storage
        .from('public')
        .upload(filePath, file);
        
      if (uploadError) throw uploadError;
      
      const { data } = supabase.storage.from('public').getPublicUrl(filePath);
      setForm({ ...form, featured_image: data.publicUrl });
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setImageUploading(false);
    }
  };


   // Function to auto-generate slug from title
   const generateSlug = (title: string) => {
    return title
      .toLowerCase()
      .replace(/[^\w\s-]/g, '') // Remove special characters
      .replace(/\s+/g, '-') // Replace spaces with hyphens
      .replace(/-+/g, '-'); // Remove consecutive hyphens
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    setForm({
      ...form,
      title: newTitle,
      // Only auto-generate slug if it's a new post or slug is empty
      slug: !id || form.slug === '' ? generateSlug(newTitle) : form.slug,
    });
  };


  const quillModules = {
    toolbar: {
      container: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        ['blockquote', 'code-block'],
        ['link', 'image'],
        ['clean']
      ],
    }
  };

  return (
    <div className="tw-max-w-4xl tw-mx-auto tw-px-4 tw-py-8">
      <h1 className="tw-text-3xl tw-font-bold tw-text-gray-900 tw-mb-8">
        {id ? 'Edit Post' : 'New Post'}
      </h1>

      <form onSubmit={handleSubmit} className="tw-space-y-6">
        <div>
          <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">Title</label>
          <input
            type="text"
            value={form.title}
            onChange={(e) => setForm({ ...form, title: e.target.value })}
            className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border-gray-300 tw-shadow-sm focus:tw-border-indigo-500 focus:tw-ring-indigo-500"
            required
          />
        </div>

        <div>
          <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">Slug</label>
          <input
            type="text"
            value={form.slug}
            onChange={(e) => setForm({ ...form, slug: e.target.value })}
            className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border-gray-300 tw-shadow-sm focus:tw-border-indigo-500 focus:tw-ring-indigo-500"
            required
          />
        </div>
        
        <div>
          <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">Featured Image</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="tw-mt-1 tw-block tw-w-full"
          />
          {imageUploading && <p className="tw-text-sm tw-text-gray-500 tw-mt-1">Uploading image...</p>}
          {form.featured_image && (
            <div className="tw-mt-2">
              <img 
                src={form.featured_image} 
                alt="Featured" 
                className="tw-h-32 tw-w-auto tw-object-cover tw-rounded"
              />
            </div>
          )}
        </div>

        <div>
          <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">Content</label>
          <ReactQuill
           ref={reactQuillRef}
            theme="snow"
            value={form.content}
            onChange={(content) => setForm({ ...form, content })}
            modules={quillModules}
            className="tw-mt-1"
          />
          <p className="tw-text-sm tw-text-gray-500 tw-mt-1">Tip: You can add images by clicking the image icon in the toolbar.</p>

        </div>
  

        <div>
          <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">Excerpt</label>
          <textarea
            value={form.excerpt}
            onChange={(e) => setForm({ ...form, excerpt: e.target.value })}
            className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border-gray-300 tw-shadow-sm focus:tw-border-indigo-500 focus:tw-ring-indigo-500"
            rows={3}
          />
        </div>

        <div>
          <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">Author</label>
          <input
            type="text"
            value={form.author}
            onChange={(e) => setForm({ ...form, author: e.target.value })}
            className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border-gray-300 tw-shadow-sm focus:tw-border-indigo-500 focus:tw-ring-indigo-500"
          />
        </div>

        <div className="tw-flex tw-justify-end tw-gap-4">
          <button
            type="button"
            onClick={() => navigate('/admin/blog')}
            className="tw-bg-white tw-py-2 tw-px-4 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={loading || imageUploading}
            className="tw-bg-indigo-600 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-py-2 tw-px-4 tw-inline-flex tw-justify-center tw-text-sm tw-font-medium tw-text-white hover:tw-bg-indigo-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500"
          >
            {loading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default BlogPostEditor;