// src/pages/FocusOKR.tsx

import React, { useState, useEffect } from 'react';
import { OKR, Comment } from '../types';
import FokusOKRTile from '../components/FokusOKRTiles';
import {
  Typography,
  Box,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import axios from 'axios';
import supabase from '../Auth/supabase';
import ErrorIcon from '@mui/icons-material/Error';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useLocation, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactQuill from 'react-quill';
import DOMPurify from 'dompurify';




interface Team {
  name: string;
}

interface User {
  name: string;
  assigneeId: string;
}

interface FocusOKRProps {
  okrs: OKR[];
}

const FocusOKR: React.FC<FocusOKRProps> = ({ okrs }) => {
  const [filterType, setFilterType] = useState<string>('All');
  const [teams, setTeams] = useState<Team[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<string>('');
  const [timeFilterType, setTimeFilterType] = useState<string>('All');
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedOKR, setSelectedOKR] = useState<OKR | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [filteredOkrs, setFilteredOkrs] = useState<OKR[]>([]);
  const [allOkrs, setAllOkrs] = useState<OKR[]>([]); // New state to hold all OKRs
  const [overdueOkrs, setOverdueOkrs] = useState<OKR[]>([]);
  const [upcomingOkrs, setUpcomingOkrs] = useState<OKR[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [newComment, setNewComment] = useState('');
  const [currentUserName, setCurrentUserName] = useState<string>('User');
const [editingCommentIndex, setEditingCommentIndex] = useState<number | null>(null);
const [editedCommentText, setEditedCommentText] = useState('');




  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://ontrakk.com';

  // Fetch teams, users, and OKRs
  useEffect(() => {
    const fetchData = async () => {
      try {
        const {
          data: { session },
        } = await supabase.auth.getSession();
        const token = session?.access_token;

        if (token) {
          // Fetch teams
          const teamsResponse = await axios.get<Team[]>(`${apiBaseUrl}/api/teams`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setTeams(teamsResponse.data);

          // Fetch users
          const usersResponse = await axios.get<User[]>(`${apiBaseUrl}/api/okr-owners`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setUsers(usersResponse.data);

          // Fetch all OKRs
          const okrsResponse = await axios.get<OKR[]>(`${apiBaseUrl}/api/okrs`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setAllOkrs(okrsResponse.data);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setError('Failed to fetch data');
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const {
          data: { session },
        } = await supabase.auth.getSession();
        const token = session?.access_token;
        const userEmail = session?.user?.email;
  
        if (token && userEmail) {
          // Same approach as in TopBar
          const response = await axios.get(`${apiBaseUrl}/api/okr-owners`, {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: 'application/json',
            },
          });
  
          const owners = response.data;
          const currentUser = owners.find((owner: any) => owner.email === userEmail);
  
          if (currentUser?.name) {
            setCurrentUserName(currentUser.name);
          } else {
            setCurrentUserName('User');
          }
        }
      } catch (error) {
        console.error('Failed to fetch user name:', error);
        setCurrentUserName('User');
      }
    };
  
    fetchUserName();
  }, []);

  const isUpcomingOrOverdue = (deadlineString: string): { overdue: boolean; upcoming: boolean } => {
    const today = new Date();
    const deadline = new Date(deadlineString);
    const timeDiff = deadline.getTime() - today.getTime();
    const daysDiff = timeDiff / (1000 * 3600 * 24);
  
    return {
      overdue: daysDiff < 0,
      upcoming: daysDiff >= 0 && daysDiff <= 14 // Next 2 weeks
    };
  };

  

  // Apply filters
  useEffect(() => {
    let filtered = allOkrs;

    // Filter for Red and Amber statuses
    filtered = filtered.filter((okr) => okr.Status === 'Red' || okr.Status === 'Amber');

    if (filterType === 'Company') {
      filtered = filtered.filter((okr) => okr.GoalType === 'Company');
    } else if (filterType === 'Team') {
      if (selectedTeam) {
        filtered = filtered.filter((okr) => okr.Team === selectedTeam);
      }
    } else if (filterType === 'Individual') {
      if (selectedUser) {
        filtered = filtered.filter(
          (okr) => okr.AssigneeId === selectedUser || okr.AssigneeName === selectedUser
        );
      }
    }

    if (timeFilterType === 'Custom' && fromDate && toDate) {
      filtered = filtered.filter((okr) => {
        const deadline = new Date(okr.TimePeriod);
        return deadline >= fromDate && deadline <= toDate;
      });
    }

    setFilteredOkrs(filtered);
  // Set overdue and upcoming OKRs
  const overdueOkrs = filtered.filter(
    (okr) => okr.TimePeriod && isUpcomingOrOverdue(okr.TimePeriod).overdue
  );
  const upcomingOkrs = filtered.filter(
    (okr) => okr.TimePeriod && isUpcomingOrOverdue(okr.TimePeriod).upcoming
  );
  setOverdueOkrs(overdueOkrs);
  setUpcomingOkrs(upcomingOkrs);

  }, [
    allOkrs,
    filterType,
    selectedTeam,
    selectedUser,
    timeFilterType,
    fromDate,
    toDate,
  ]);

  // Separate red and amber OKRs
  const redOkrs = filteredOkrs.filter((okr) => okr.Status === 'Red');
  const amberOkrs = filteredOkrs.filter((okr) => okr.Status === 'Amber');


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const okrId = searchParams.get('okr');
  
    if (okrId && allOkrs.length > 0) {
      const okr = allOkrs.find(o => o._id === okrId);
      if (okr) {
        setSelectedOKR(okr);
        setOpenModal(true);
      }
    }
  }, [location.search, allOkrs]);

  // Handler to open the modal with selected OKR
  const handleOKRClick = (okr: OKR) => {
    setSelectedOKR(okr);
    setOpenModal(true);
  };

  // Handler to close the modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedOKR(null);
  };

  // Handler to save the updated OKR
  const handleSaveOKR = async () => {
    try {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      const token = session?.access_token;

      if (token && selectedOKR) {
        const { _id, ...okrData } = selectedOKR;

          // 1) If the user typed a new comment, build it:
             let updatedOKR = { ...selectedOKR };
             if (newComment.trim()) {
               const comment: Comment = {
                text: newComment.trim(),
                author: currentUserName, 
                 source: 'OnTrakk',
                 timestamp: new Date().toISOString(),
               };
               updatedOKR = {
                 ...updatedOKR,
                 comments: [...(updatedOKR.comments || []), comment],
               };
             }  

        const updateData = {
          Goal: okrData.Goal,
          AssigneeId: okrData.AssigneeId,
          AssigneeName: okrData.AssigneeName,
          Team: okrData.Team,
          Status: okrData.Status,
          TimePeriod: okrData.TimePeriod,
          comments: updatedOKR.comments,
        };


        await axios.put(`${apiBaseUrl}/api/okrs/${_id}`, updateData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Refresh OKRs after update
        await fetchUpdatedOKRs();
        handleCloseModal();
      }
    } catch (error) {
      console.error('Failed to update OKR:', error);
      setError('Failed to update OKR');
    }
  };

  const handleEditComment = (index: number) => {
    if (!selectedOKR?.comments) return;
    
    setEditingCommentIndex(index);
    setEditedCommentText(selectedOKR.comments[index]?.text || '');
  };
  
  
  const handleSaveEdit = async () => {
    if (editingCommentIndex === null || !selectedOKR?.comments) return;
  
    try {
      const { data: { session } } = await supabase.auth.getSession();
      
      if (!session?.access_token) return;
  
      await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/api/okrs/${selectedOKR._id}/comments/${editingCommentIndex}`,
        { text: editedCommentText },
        {
          headers: {
            Authorization: `Bearer ${session.access_token}`
          }
        }
      );
  
  
      // Update local state
      const updatedComments = [...(selectedOKR.comments || [])];
      if (updatedComments[editingCommentIndex]) {
        updatedComments[editingCommentIndex] = {
          ...updatedComments[editingCommentIndex],
          text: editedCommentText,
        };
  
        setSelectedOKR({
          ...selectedOKR,
          comments: updatedComments
        });
      }
  
      setEditingCommentIndex(null);
      setEditedCommentText('');
    } catch (error) {
      console.error('Failed to edit comment:', error);
    }
  };
  
  
  const handleDeleteComment = async (index: number) => {
    if (!selectedOKR?.comments) return;
  
    if (!window.confirm('Are you sure you want to delete this comment?')) {
      return;
    }
  
    try {
      const { data: { session } } = await supabase.auth.getSession();
      
      if (!session?.access_token) return;
  
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/okrs/${selectedOKR._id}/comments/${index}`,
        {
          headers: {
            Authorization: `Bearer ${session.access_token}`
          }
        }
      );
      
      // Update local state
      setSelectedOKR({
        ...selectedOKR,
        comments: selectedOKR.comments.filter((_, i) => i !== index)
      });
    } catch (error) {
      console.error('Failed to delete comment:', error);
    }
  };    

  // Function to fetch updated OKRs
  const fetchUpdatedOKRs = async () => {
    try {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      const token = session?.access_token;

      if (token) {
        const okrsResponse = await axios.get<OKR[]>(`${apiBaseUrl}/api/okrs`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAllOkrs(okrsResponse.data);
      }
    } catch (error) {
      console.error('Failed to fetch updated OKRs:', error);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      {/* Filter Section */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
        <Typography variant="body1" sx={{ mr: 1 }}>
          Filter by:
        </Typography>

        <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
          <InputLabel>Type</InputLabel>
          <Select
            value={filterType}
            onChange={(e) => {
              setFilterType(e.target.value);
              setSelectedTeam('');
              setSelectedUser('');
            }}
            label="Type"
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Company">Company</MenuItem>
            <MenuItem value="Team">Team</MenuItem>
            <MenuItem value="Individual">Individual</MenuItem>
          </Select>
        </FormControl>

        {filterType === 'Team' && (
          <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
            <InputLabel>Select Team</InputLabel>
            <Select
              value={selectedTeam}
              onChange={(e) => setSelectedTeam(e.target.value)}
              label="Select Team"
            >
              {teams.map((team, index) => (
                <MenuItem key={index} value={team.name}>
                  {team.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {filterType === 'Individual' && (
          <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
            <InputLabel>Select User</InputLabel>
            <Select
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
              label="Select User"
            >
              {users.map((user, index) => (
                <MenuItem key={index} value={user.assigneeId}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
          <InputLabel>Time Period</InputLabel>
          <Select
            value={timeFilterType}
            onChange={(e) => {
              setTimeFilterType(e.target.value);
              if (e.target.value === 'All') {
                setFromDate(null);
                setToDate(null);
              }
            }}
            label="Time Period"
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Custom">Custom</MenuItem>
          </Select>
        </FormControl>

        {timeFilterType === 'Custom' && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="From"
            value={fromDate}
            onChange={(newValue: Date | null) => setFromDate(newValue)}
            slotProps={{
              textField: {
                variant: 'outlined',
                size: 'small',
              },
            }}
          />
          <DatePicker
            label="To"
            value={toDate}
            onChange={(newValue: Date | null) => setToDate(newValue)}
            slotProps={{
              textField: {
                variant: 'outlined',
                size: 'small',
              },
            }}
          />
        </LocalizationProvider>
        )}
      </Box>

{/* Display OKRs */}
<Grid container spacing={3}>
  {/* Red OKRs Column */}
  <Grid item xs={12} sm={4}>
    <Paper sx={{ p: 2, height: '100%' }}>
      <Typography variant="h6" sx={{ color: '#f44336' }}>
      Red OKRs ({redOkrs.length})
      </Typography>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        {redOkrs.length > 0 ? (
          redOkrs.map((okr, index) => (
            <Grid item xs={12} key={index}>
              <FokusOKRTile okr={okr} onClick={() => handleOKRClick(okr)} />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography sx={{ ml: 2 }}>No red OKRs found.</Typography>
          </Grid>
        )}
      </Grid>
    </Paper>
  </Grid>

  {/* Amber OKRs Column */}
  <Grid item xs={12} sm={4}>
    <Paper sx={{ p: 2, height: '100%' }}>
      <Typography variant="h6" sx={{ color: '#ff9800' }}>
      Amber OKRs ({amberOkrs.length})
      </Typography>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        {amberOkrs.length > 0 ? (
          amberOkrs.map((okr, index) => (
            <Grid item xs={12} key={index}>
              <FokusOKRTile okr={okr} onClick={() => handleOKRClick(okr)} />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography sx={{ ml: 2 }}>No amber OKRs found.</Typography>
          </Grid>
        )}
      </Grid>
    </Paper>
  </Grid>

      {/* New Deadlines Column */}
      <Grid item xs={12} sm={4}>
        <Paper sx={{ p: 2, height: '100%' }}>
          <Typography variant="h6" gutterBottom>
            Deadlines
          </Typography>
          
          {/* Overdue OKRs */}
          <Box sx={{ mb: 4 }}>
            <Typography
              variant="subtitle1"
              sx={{ 
                display: 'flex', 
                alignItems: 'center',
                color: '#f44336',
                mb: 2
              }}
            >
          <ErrorIcon sx={{ mr: 1 }} /> Overdue OKRs ({overdueOkrs.length})
      </Typography>
            {overdueOkrs.length > 0 ? (
              overdueOkrs.map((okr, index) => (
                <Box 
                  key={index} 
                  sx={{ 
                    mb: 2,
                    cursor: 'pointer',
                    '&:hover': { opacity: 0.8 }
                  }}
                  onClick={() => handleOKRClick(okr)}
                >
                  <Typography sx={{ fontWeight: 'medium' }}>
                    {okr.Goal}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Due: {new Date(okr.TimePeriod).toLocaleDateString()}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography color="text.secondary">
                No overdue OKRs
              </Typography>
            )}
          </Box>

          {/* Upcoming Deadlines */}
          <Box>
            <Typography
              variant="subtitle1"
              sx={{ 
                display: 'flex', 
                alignItems: 'center',
                color: '#1976d2',
                mb: 2
              }}
            >
          <AccessTimeIcon sx={{ mr: 1 }} /> Upcoming Deadlines ({upcomingOkrs.length})
          </Typography>
            {upcomingOkrs.length > 0 ? (
              upcomingOkrs.map((okr, index) => (
                <Box 
                  key={index} 
                  sx={{ 
                    mb: 2,
                    cursor: 'pointer',
                    '&:hover': { opacity: 0.8 }
                  }}
                  onClick={() => handleOKRClick(okr)}
                >
                  <Typography sx={{ fontWeight: 'medium' }}>
                    {okr.Goal}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Due: {new Date(okr.TimePeriod).toLocaleDateString()}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography color="text.secondary">
                No upcoming deadlines
              </Typography>
            )}
          </Box>
        </Paper>
      </Grid>
      </Grid>

      {/* Modal for OKR details */}
      {selectedOKR && (
        <Dialog
          open={openModal}
          onClose={handleCloseModal}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Edit OKR</DialogTitle>
          <DialogContent>
            <TextField
              label="Goal"
              name="Goal"
              value={selectedOKR.Goal}
              onChange={(e) =>
                setSelectedOKR({
                  ...selectedOKR!,
                  Goal: e.target.value,
                })
              }
              fullWidth
              sx={{ mb: 2 }}
            />

            {/* Owner Selection */}
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Owner</InputLabel>
              <Select
                value={selectedOKR.AssigneeId || ''}
                onChange={(e) => {
                  const selectedUser = users.find(
                    (user) => user.assigneeId === e.target.value
                  );
                  setSelectedOKR({
                    ...selectedOKR!,
                    AssigneeId: e.target.value as string,
                    AssigneeName: selectedUser ? selectedUser.name : '',
                  });
                }}
                label="Owner"
              >
                {users.map((user) => (
                  <MenuItem key={user.assigneeId} value={user.assigneeId}>
                    {user.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Team Selection */}
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Team</InputLabel>
              <Select
                value={selectedOKR.Team || ''}
                onChange={(e) =>
                  setSelectedOKR({
                    ...selectedOKR!,
                    Team: e.target.value as string,
                  })
                }
                label="Team"
              >
                {teams.map((team, index) => (
                  <MenuItem key={index} value={team.name}>
                    {team.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={selectedOKR.Status || ''}
                onChange={(e) =>
                  setSelectedOKR({
                    ...selectedOKR!,
                    Status: e.target.value as string,
                  })
                }
                label="Status"
              >
                <MenuItem value="Green">Green</MenuItem>
                <MenuItem value="Amber">Amber</MenuItem>
                <MenuItem value="Red">Red</MenuItem>
                <MenuItem value="Completed">Completed</MenuItem>
                <MenuItem value="Failed">Failed</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Deadline"
              name="TimePeriod"
              type="date"
              value={
                selectedOKR.TimePeriod
                  ? new Date(selectedOKR.TimePeriod).toISOString().split('T')[0]
                  : ''
              }
              onChange={(e) =>
                setSelectedOKR({
                  ...selectedOKR!,
                  TimePeriod: e.target.value,
                })
              }
              fullWidth
              sx={{ mb: 2 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            
            {/* 1) New Comment Input */}
            <Box sx={{ mb: 2 }}>
            <ReactQuill
              theme="snow"
              value={newComment}
              onChange={setNewComment}
              placeholder="Add a comment..."
              style={{ backgroundColor: 'white' }}
            />
          </Box>


            {/* 2) Comments List */}
            {selectedOKR.comments && selectedOKR.comments.length > 0 ? (
  <Paper variant="outlined" sx={{ maxHeight: 250, overflow: 'auto', p: 1 }}>
    {selectedOKR.comments
      .sort(
        (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
      )
      .map((comment, index) => (
        <Box key={index} sx={{ mb: 2 }}>
          {editingCommentIndex === index ? (
            <>
              <ReactQuill
            theme="snow"
            value={editedCommentText}
            onChange={setEditedCommentText}
            placeholder="Edit your comment..."
            style={{ backgroundColor: 'white', marginBottom: '0.5rem' }}
                  />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                <Button
                  size="small"
                  onClick={() => {
                    setEditingCommentIndex(null);
                    setEditedCommentText('');
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleSaveEdit}
                >
                  Save
                </Button>
              </Box>
            </>
          ) : (
            <>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Box
                        sx={{
                          mt: 1,
                          '& p': { mb: 1, fontSize: '0.9rem', color: 'text.primary' },
                          '& ul, & ol': { pl: 2, mb: 1 },
                          '& ul': { listStyleType: 'disc' },
                          '& ol': { listStyleType: 'decimal' },
                          '& li': { fontSize: '0.9rem', listStylePosition: 'outside', ml: 0 },
                          '& strong': { fontWeight: 600 },
                        }}
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(comment.text) }}
                      />
                {comment.source === 'OnTrakk' && (
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <IconButton
                      size="small"
                      onClick={() => handleEditComment(index)}
                      >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleDeleteComment(index)}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </Box>
                            )}
                          </Box>
                        </>
                      )}
                    </Box>
                  ))}
              </Paper>
            ) : (
              <Typography variant="body2" color="text.secondary">
                No comments yet
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal}>Cancel</Button>
            <Button onClick={handleSaveOKR} color="primary" variant="contained">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default FocusOKR;
