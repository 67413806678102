// src/landing/LandingPage.tsx

import React, { useEffect } from 'react';
import Hero from './components/sections/Hero';
import WhySection from './components/sections/WhySection';
import HowSection from './components/sections/HowSection';
import AudienceSection from './components/sections/AudienceSection';
import SignupSection from './components/sections/SignupSection';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import './styles/landing.css';  
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';




const LandingPage: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    // Track page view with referrer information
    const referrer = document.referrer;
    const source = new URLSearchParams(location.search).get('utm_source') || 'direct';
    const medium = new URLSearchParams(location.search).get('utm_medium') || 'none';
    const campaign = new URLSearchParams(location.search).get('utm_campaign') || 'none';

    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
      title: "Landing Page"
    });

    // Track source data as custom dimensions
    ReactGA.event({
      category: "Visit",
      action: "Landing Page View",
      label: source
    }); 
    ReactGA.set({
      source: source,
      medium: medium,
      campaign: campaign,
      referrer: referrer
  })}, [location]); 
    
  // Track section views as user scrolls
  useEffect(() => {
    const handleScroll = () => {
      const sections = ['hero', 'why-section', 'how-section', 'audience-section', 'signup-section'];
      const scrollPosition = window.scrollY + window.innerHeight * 0.7;

      sections.forEach(sectionId => {
        const element = document.getElementById(sectionId);
        if (element) {
          const sectionTop = element.offsetTop;
          const sectionHeight = element.offsetHeight;
          
          if (scrollPosition >= sectionTop && scrollPosition <= sectionTop + sectionHeight) {
            // Only track sections once
            if (!element.dataset.tracked) {
              ReactGA.event({
                category: "Engagement",
                action: "Section View",
                label: sectionId
              });
              element.dataset.tracked = 'true';
            }
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div id="landing-page" className="tw-min-h-screen tw-bg-white">
      <Navigation />
      <div id="hero"><Hero /></div>
      <div id="why-section"><WhySection /></div>
      <div id="how-section"><HowSection /></div>
      <div id="audience-section"><AudienceSection /></div>
      <div id="signup-section"><SignupSection /></div>
      <Footer />
    </div>
  );
};

export default LandingPage;