// src/landing/components/PrivacyPage.tsx
import React from 'react';
import { Box, Typography, Container, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const PrivacyPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 4 }}>
        {/* Back Button */}
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/')}
          sx={{
            mb: 4,
            color: 'text.secondary',
            '&:hover': {
              bgcolor: 'grey.100',
            },
          }}
        >
          Back to OnTrakk
        </Button>

        <Typography variant="h3" component="h1" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          Last updated: January 11, 2025
        </Typography>

        <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
          1. Information We Collect
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
          1.1 Automatically Collected Information
        </Typography>
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          <ul>
            <li>Slack workspace ID</li>
            <li>User ID and email</li>
            <li>Usage data and activity logs on OnTrakk</li>
          </ul>
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
          1.2 User-Provided Information
        </Typography>
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          <ul>
            <li>OKR data and goals</li>
            <li>Team information</li>
            <li>Comments and updates</li>
            <li>Profile information</li>
          </ul>
        </Typography>

        <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
          2. How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use collected information to:
        </Typography>
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          <ul>
            <li>- Provide OKR management services</li>
            <li>- Send automated notifications</li>
            <li>- Generate performance analytics</li>
            <li>- Improve our services</li>
          </ul>
        </Typography>

        <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
          3. Data Storage and Security
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
          3.1 Data Storage
        </Typography>
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          <ul>
            <li>We use third-party services for user authentication and data storage, including:
              <ul>
                <li>- Goals and objectives</li>
                <li>- Performance metrics</li>
                <li>- Team structures</li>
                <li>- Update history</li>
              </ul>
            </li>
            <li>Regular backups are performed.</li>
          </ul>
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
          3.2 Security Measures
        </Typography>
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          <ul>
            <li>Industry-standard security protocols</li>
            <li>Access controls and authentication</li>
            <li>Regular security audits</li>
          </ul>
        </Typography>

        <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
          4. Information Sharing
        </Typography>
        <Typography variant="body1" paragraph>
          We share information only:
        </Typography>
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          <ul>
            <li>- Within your organization as authorized</li>
            <li>- With third-party service providers as needed</li>
            <li>- As required by law</li>
            <li>- With your explicit consent</li>
          </ul>
        </Typography>

        <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
          5. Data Retention
        </Typography>
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          <ul>
            <li>Active account data is retained until account deletion. Backup data may be retained for up to 30 days. Usage logs are retained for 90 days</li>
          </ul>
        </Typography>

        <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
          6. User Rights
        </Typography>
        <Typography variant="body1" paragraph>
          You have the right to:
        </Typography>
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          <ul>
            <li>- Access your personal data</li>
            <li>- Request data correction</li>
            <li>- Request data deletion</li>
            <li>- Export your data</li>
          </ul>
        </Typography>

        <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
          7. Third-Party Services
        </Typography>
        <Typography variant="body1" paragraph>
          Our service integrates with third-party services as required to fulfill our service. 
        </Typography>
        <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
          8. Cookie Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We use cookies to:
        </Typography>
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          <ul>
            <li>- Maintain session information</li>
            <li>- Track usage patterns</li>
            <li>- Improve user experience</li>
          </ul>
        </Typography>

        <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
          9. Changes to Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We will notify users of privacy policy changes through:
        </Typography>
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          <ul>
            <li>- Email notification</li>
            <li>- Service announcement</li>
            <li>- Website posting</li>
          </ul>
        </Typography>

        <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
          10. Contact Information
        </Typography>
        <Typography variant="body1" paragraph>
          For privacy-related inquiries:
        </Typography>
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          <ul>
            <li>Email: abel@ontrakk.com</li>
            <li>Website: https://ontrakk.com/contact-us</li>
          </ul>
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPage;