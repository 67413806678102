// src/landing/components/Footer.tsx
import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer className="tw-bg-white">
      <div className="tw-max-w-7xl tw-mx-auto tw-py-12 tw-px-4 tw-sm:px-6 tw-lg:px-8">
        <div className="tw-grid tw-grid-cols-2 tw-md:grid-cols-4 tw-gap-8">
          <div>
            <h3 className="tw-text-sm tw-font-semibold tw-text-gray-400 tw-tracking-wider tw-uppercase">Company</h3>
            <ul className="tw-mt-4 tw-space-y-4">
          
              <li>
              <Link to="/privacy" className="tw-text-base tw-text-gray-500 hover:tw-text-gray-900">
                  Privacy
                </Link>              
  
                </li>
                <li>
                <Link to="/terms" className="tw-text-base tw-text-gray-500 hover:tw-text-gray-900">
                  Terms
                </Link>
              </li>
              <li>
                <Link to="/blog" className="tw-text-base tw-text-gray-500 hover:tw-text-gray-900">
                  Blog
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="tw-mt-8 tw-border-t tw-border-gray-200 tw-pt-8">
          <p className="tw-text-base tw-text-gray-400 tw-text-center">
            © {new Date().getFullYear()} OnTrakk. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;