import React, { useState, useEffect, useRef} from 'react';
import Tree, { RenderCustomNodeElementFn, RawNodeDatum } from 'react-d3-tree';
import supabase from '../Auth/supabase';
import { Box, IconButton, Typography } from '@mui/material';
import theme from 'theme';
import axios from 'axios';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactMarkdown from 'react-markdown';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ReactQuill from 'react-quill';
import DOMPurify from 'dompurify';






const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://ontrakk.com';

interface OKR {
  _id: string;
  Goal: string;
  GoalType: string;
  Team: string;
  AssigneeId?: string;
  AssigneeName: string;
  TimePeriod: string;
  Status: string;
  comments?: Comment[];
  ParentGoalId?: string;
  CompletionDate?: string;
  FailureDate?: string;
}

interface Comment {
  text: string;
  author: string;
  source: 'Linear' | 'OnTrakk';
  timestamp: string;
}

interface TreeNode {
  name: string;
  _id?: string;
  ParentGoalId?: string;
  attributes?: {
    type: string;
    status: string;
    owner?: string;
    team?: string;
  };
  collapsed?: boolean;
  children?: TreeNode[];
}

function transformOKRs(okrs: OKR[]): TreeNode {
  const activeOKRs = okrs.filter(
    (okr) => okr.Status !== 'Completed' && okr.Status !== 'Failed'
  );

  // Create lookup maps for each type of OKR
  const okrMap = new Map<string, TreeNode>();
  
  // First pass: Create nodes for all OKRs
  activeOKRs.forEach((okr) => {
    okrMap.set(okr._id, {
      name: okr.Goal,
      _id: okr._id,
      ParentGoalId: okr.ParentGoalId,
      children: [],
      attributes: {
        type: okr.GoalType,
        status: okr.Status,
        owner: okr.AssigneeName,
        team: okr.Team,
      },
      collapsed: false,
    });
  });

  // Second pass: Build the hierarchy
  const rootChildren: TreeNode[] = [];
  activeOKRs.forEach((okr) => {
    const node = okrMap.get(okr._id);
    if (!node) return;

    if (okr.ParentGoalId && okrMap.has(okr.ParentGoalId)) {
      // This OKR has a parent, add it to parent's children
      const parentNode = okrMap.get(okr.ParentGoalId);
      if (parentNode && parentNode.children) {
        parentNode.children.push(node);
      }
    } else if (okr.GoalType === 'Company') {
      // Company OKRs with no parent go to root
      rootChildren.push(node);
    }
  });

  // Create root node
  const rootNode: TreeNode = {
    name: 'All OKRs',
    _id: 'root',
    children: rootChildren,
    attributes: {
      type: 'Root',
      status: '',
      owner: '',
      team: '',
    },
    collapsed: false,
  };

  return rootNode;
}

function OKRRelationships() {
  const location = useLocation();
  const navigate = useNavigate();
  const [okrs, setOkrs] = useState<TreeNode | null>(null);
  const [okrDataById, setOkrDataById] = useState<Map<string, OKR>>(new Map());
  const [error, setError] = useState<string | null>(null);
  const [selectedNodes, setSelectedNodes] = useState<{ [key: string]: boolean }>({});
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedOKR, setSelectedOKR] = useState<OKR | null>(null);
  const [newComment, setNewComment] = useState('');
  const [currentUserName, setCurrentUserName] = useState('User');
  const [editingCommentIndex, setEditingCommentIndex] = useState<number | null>(null);
  const [editedCommentText, setEditedCommentText] = useState('');
  const commentInputRef = useRef<HTMLTextAreaElement | null>(null);



  const fetchOKRs = async () => {
    try {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      const token = session?.access_token;

      if (token) {
        const response = await fetch(`${apiBaseUrl}/api/okrs`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();

        // Create a mapping from OKR IDs to OKR data
        const okrDataMap = new Map<string, OKR>();
        data.forEach((okr: OKR) => {
          okrDataMap.set(okr._id, okr);
        });
        setOkrDataById(okrDataMap);

        const treeData = transformOKRs(data);
        setOkrs(treeData);
      } else {
        console.error('No auth token found');
        setError('No auth token found');
      }
    } catch (error) {
      console.error('Failed to fetch OKRs:', error);
      setError('Failed to fetch OKRs');
    }
  };

  const fetchUserName = async () => {
    try {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      const token = session?.access_token;
      const userEmail = session?.user?.email;

      if (token && userEmail) {
        const response = await axios.get(`${apiBaseUrl}/api/okr-owners`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const owners = response.data;
        const currentUser = owners.find((owner: any) => owner.email === userEmail);
        if (currentUser?.name) {
          setCurrentUserName(currentUser.name);
        }
      }
    } catch (err) {
      console.error('Failed to fetch user name:', err);
    }
  };

  useEffect(() => {
    fetchOKRs();
    fetchUserName();
  }, []);
  
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const okrId = searchParams.get('okr');
  
    if (okrId && okrDataById.size > 0) {
      const okr = okrDataById.get(okrId);
      if (okr) {
        setSelectedOKR(okr);
        setOpenModal(true);
      }
    }
  }, [location.search, okrDataById]);

  const handleNodeClick = (nodeDatum: RawNodeDatum) => {
    const customNode = nodeDatum as TreeNode;

    if (customNode._id && customNode._id !== 'root') {
      const okr = okrDataById.get(customNode._id);
      if (okr) {
        setSelectedOKR(okr);
        setOpenModal(true);
        navigate(`/okr-relationships?okr=${okr._id}`); 
      }
    }

    // Existing code to handle selected nodes (if any)
    const newSelectedNodes: { [key: string]: boolean } = {};
    if (customNode.attributes?.type === 'Company') {
      newSelectedNodes[customNode._id || ''] = true;
    } else if (customNode.attributes?.type === 'Team') {
      newSelectedNodes[customNode._id || ''] = true;
      if (customNode.ParentGoalId) {
        newSelectedNodes[customNode.ParentGoalId] = true;
      }
    }
    setSelectedNodes(newSelectedNodes);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedOKR(null);
    navigate('/okr-relationships');
  };

  const handleSaveOKR = async () => {
    try {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      const token = session?.access_token;

      if (token && selectedOKR) {
        const { _id, ...okrData } = selectedOKR;

        // If there's a new comment, create and append it
        let updatedOKR = { ...selectedOKR };
        if (newComment.trim()) {
          const comment: Comment = {
            text: newComment.trim(),
            author: currentUserName,
            source: 'OnTrakk',
            timestamp: new Date().toISOString(),
          };
          updatedOKR = {
            ...updatedOKR,
            comments: [...(updatedOKR.comments || []), comment],
          };
        }

        // Build the object for PUT
        const updateData = {
          Goal: updatedOKR.Goal,
          AssigneeName: updatedOKR.AssigneeName,
          Status: updatedOKR.Status,
          TimePeriod: updatedOKR.TimePeriod,
          comments: updatedOKR.comments,
        };

        await axios.put(
          `${apiBaseUrl}/api/okrs/${_id}`,
          updateData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setNewComment('');
        setSelectedOKR(updatedOKR);


        // Refresh OKRs after update
        await fetchOKRs();
        handleCloseModal();
      }
    } catch (error) {
      console.error('Failed to update OKR:', error);
      setError('Failed to update OKR');
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  const handleEditComment = (index: number) => {
    if (!selectedOKR?.comments) return;
    
    setEditingCommentIndex(index);
    setEditedCommentText(selectedOKR.comments[index]?.text || '');
  };
  
  
  const handleSaveEdit = async () => {
    if (editingCommentIndex === null || !selectedOKR?.comments) return;
  
    try {
      const { data: { session } } = await supabase.auth.getSession();
      
      if (!session?.access_token) return;
  
      await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/api/okrs/${selectedOKR._id}/comments/${editingCommentIndex}`,
        { text: editedCommentText },
        {
          headers: {
            Authorization: `Bearer ${session.access_token}`
          }
        }
      );
  
  
      // Update local state
      const updatedComments = [...(selectedOKR.comments || [])];
      if (updatedComments[editingCommentIndex]) {
        updatedComments[editingCommentIndex] = {
          ...updatedComments[editingCommentIndex],
          text: editedCommentText,
        };
  
        setSelectedOKR({
          ...selectedOKR,
          comments: updatedComments
        });
      }
  
      setEditingCommentIndex(null);
      setEditedCommentText('');
    } catch (error) {
      console.error('Failed to edit comment:', error);
    }
  };
  
  
  const handleDeleteComment = async (index: number) => {
    if (!selectedOKR?.comments) return;
  
    if (!window.confirm('Are you sure you want to delete this comment?')) {
      return;
    }
  
    try {
      const { data: { session } } = await supabase.auth.getSession();
      
      if (!session?.access_token) return;
  
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/okrs/${selectedOKR._id}/comments/${index}`,
        {
          headers: {
            Authorization: `Bearer ${session.access_token}`
          }
        }
      );
      
      // Update local state
      setSelectedOKR({
        ...selectedOKR,
        comments: selectedOKR.comments.filter((_, i) => i !== index)
      });
    } catch (error) {
      console.error('Failed to delete comment:', error);
    }
  };    

  const renderNode: RenderCustomNodeElementFn = ({ nodeDatum }) => {
    const customNode = nodeDatum as TreeNode;
    const { type = 'Root', status = '', owner = '', team = '' } =
      customNode.attributes || {};

    const statusColors: { [key: string]: string } = {
      Green: '#4caf50',
      Amber: '#ff9800',
      Red: '#f44336',
    };

    const getBorderColor = () => {
      if (selectedNodes[customNode._id || '']) return '#f44336';
      switch (type) {
        case 'Company': return theme.palette.primary.main;
        case 'Team': return theme.palette.success.main;
        default: return theme.palette.grey[400];
      }
    };




  
    return (
      <g className="tree-node" onClick={() => handleNodeClick(customNode)}>
        {/* Card Background */}
        <rect
          x={-130}
          y={-80}
          width="260"
          height="130"
          fill="white"
          stroke={getBorderColor()}
          strokeWidth="2"
          rx="10"
          ry="10"
          filter="drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.1))"
          />
  
        {/* Goal Title */}
        <foreignObject x={-120} y={-70} width={240} height={60}>
          <div style={{
            fontSize: '14px',
            fontWeight: 500,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            textOverflow: 'ellipsis',
            lineHeight: '1.4em',
            padding: '0 8px'
          }}>
            {customNode.name}
          </div>
        </foreignObject>
  
        {/* Team Node Details */}
        {(type === 'Team' || type === 'Personal') && (
        <>
          {/* Team details - Updated typography */}
          <foreignObject x={-110} y={-20} width={190} height={20}>
            <Typography variant="body2" color="text.secondary">
              Owner: {owner}
            </Typography>
          </foreignObject>

          <foreignObject x={-110} y={0} width={190} height={20}>
            <Typography variant="body2" color="text.secondary">
              Team: {team}
            </Typography>
          </foreignObject>

          <foreignObject x={-110} y={20} width={190} height={20}>
            <Typography variant="body2" color="text.secondary">
              Status:
            </Typography>
          </foreignObject>
          <circle
            cx={-55}
            cy={30}
            r="8"
            fill={statusColors[status] || '#000000'}
          />
        </>
      )}
    </g>
  );
};

  const customPathFunc = (linkDatum: {
    source: { x: number; y: number };
    target: { x: number; y: number };
  }) => {
    const { source, target } = linkDatum;
    const path = `
      M${source.x},${source.y + 25}
      L${source.x},${(source.y + target.y) / 2}
      L${target.x},${(source.y + target.y) / 2}
      L${target.x},${target.y - 25}
    `;
    return path;
  };


  return (
    <Box sx={{ flexGrow: 1, padding: '20px' }}>
      <Box sx={{ display: 'flex', mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
          <Box
            sx={{
              width: 20,
              height: 20,
              border: `2px solid ${theme.palette.primary.main}`,
              mr: 1,
              borderRadius: '4px',
            }}
          ></Box>
          <Typography>Company Goals</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
          <Box
            sx={{
              width: 20,
              height: 20,
              border: `2px solid ${theme.palette.success.main}`,
              mr: 1,
              borderRadius: '4px',
            }}
          ></Box>
          <Typography>Team Goals</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
    <Box
      sx={{
        width: 20,
        height: 20,
        border: `2px solid ${theme.palette.grey[400]}`,
        mr: 1,
        borderRadius: '4px',
      }}
    ></Box>
    <Typography>Personal Goals</Typography>
  </Box>
      </Box>
      <div
        style={{
          width: '100%',
          height: '100vh',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {okrs ? (
          <Tree
          data={okrs}
          orientation="vertical"
          pathFunc={customPathFunc}
          translate={{ x: window.innerWidth / 2.5, y: 1 }}
          separation={{ siblings: 2, nonSiblings: 2 }}
          collapsible={true}
          zoomable={false}
          renderCustomNodeElement={renderNode}
          nodeSize={{ x: 200, y: 200 }}
          />
        ) : (
          <Typography>Loading...</Typography>
        )}
      </div>

      {/* Modal for OKR details */}
      {selectedOKR && (
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Edit OKR</DialogTitle>
        <DialogContent>
            <TextField
              label="Goal"
              name="Goal"
              value={selectedOKR.Goal}
              onChange={(e) =>
                setSelectedOKR({
                  ...selectedOKR!,
                  Goal: e.target.value,
                })
              }
              fullWidth
              sx={{ mb: 2 }}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={selectedOKR.Status || ''}
                onChange={(e) =>
                  setSelectedOKR({
                    ...selectedOKR!,
                    Status: e.target.value as string,
                  })
                }
                label="Status"
              >
                <MenuItem value="Green">Green</MenuItem>
                <MenuItem value="Amber">Amber</MenuItem>
                <MenuItem value="Red">Red</MenuItem>
                <MenuItem value="Completed">Completed</MenuItem>
                <MenuItem value="Failed">Failed</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Deadline"
              name="TimePeriod"
              type="date"
              value={
                selectedOKR.TimePeriod
                  ? new Date(selectedOKR.TimePeriod).toISOString().split('T')[0]
                  : ''
              }
              onChange={(e) =>
                setSelectedOKR({
                  ...selectedOKR!,
                  TimePeriod: e.target.value,
                })
              }
              fullWidth
              sx={{ mb: 2 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
              {/* New comment input */}
              <Box sx={{ mb: 2 }}>
                  <ReactQuill
                    theme="snow"
                    value={newComment}
                    onChange={setNewComment}
                    placeholder="Add a comment..."
                    style={{ backgroundColor: 'white' }}
                  />
                </Box>

            {/* Existing comments (if any) */}
            {selectedOKR.comments && selectedOKR.comments.length > 0 ? (
  <Paper variant="outlined" sx={{ maxHeight: 250, overflow: 'auto', p: 1 }}>
    {selectedOKR.comments
      .sort(
        (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
      )
      .map((comment, index) => (
        <Box key={index} sx={{ mb: 2 }}>
          {editingCommentIndex === index ? (
            <>
          <ReactQuill
            theme="snow"
            value={editedCommentText}
            onChange={setEditedCommentText}
            placeholder="Edit your comment..."
            style={{ backgroundColor: 'white', marginBottom: '0.5rem' }}
          />

              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                <Button
                  size="small"
                  onClick={() => {
                    setEditingCommentIndex(null);
                    setEditedCommentText('');
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleSaveEdit}
                >
                  Save
                </Button>
              </Box>
            </>
          ) : (
            <>
 <Box key={index} sx={{ mb: 2 }}>
  {/* Header row: author + timestamp + icons */}
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
      {comment.author}{' '}
      <Typography
        component="span"
        variant="caption"
        sx={{ color: 'text.secondary', ml: 1 }}
      >
        {new Date(comment.timestamp).toLocaleString()}
      </Typography>
    </Typography>

    {comment.source === 'OnTrakk' && (
      <Box sx={{ display: 'flex', gap: 1 }}>
        <IconButton size="small" onClick={() => handleEditComment(index)}>
          <EditIcon fontSize="small" />
        </IconButton>
        <IconButton size="small" onClick={() => handleDeleteComment(index)}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Box>
    )}
  </Box>

                          
 <Box
  sx={{
    mt: 1,
    '& p': {
      mb: 1,
      fontSize: '0.9rem',
      color: 'text.primary',
    },
    '& ul, & ol': {
      paddingLeft: '1.5rem',
      margin: 0,
    },
    '& ul': {
      listStyleType: 'disc',
    },
    '& ol': {
      listStyleType: 'decimal',
    },
    '& li': {
      fontSize: '0.9rem',
      listStylePosition: 'outside',
      marginLeft: 0,
    },
    '& strong': {
      fontWeight: 600,
    },
  }}
  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(comment.text) }}
/>


                          </Box>
                        </>
                      )}
                    </Box>
                  ))}
              </Paper>
            ) : (
              <Typography variant="body2" color="text.secondary">
                No comments yet
              </Typography>
            )}
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseModal}>Cancel</Button>
            <Button
              onClick={handleSaveOKR}
              color="primary"
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}

export default OKRRelationships;