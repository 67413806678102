import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import supabase from '../../../Auth/supabase';
import { BlogPost as BlogPostType } from '../../types/blog';
import ReactGA from 'react-ga4';

const BlogPost: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();
  const [post, setPost] = useState<BlogPostType | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPost();
  }, [slug]);

  useEffect(() => {
    if (post) {
      ReactGA.event({
        category: "Blog",
        action: "View Post",
        label: post.title
      });
    }
  }, [post]);

  const fetchPost = async () => {
    try {
      const { data, error } = await supabase
        .from('blog_posts')
        .select('*')
        .eq('slug', slug)
        .eq('is_published', true)
        .single();

      if (error) throw error;
      if (!data) {
        navigate('/blog');
        return;
      }
      
      setPost(data);
    } catch (error) {
      console.error('Error fetching post:', error);
      navigate('/blog');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="tw-text-center tw-py-12">Loading...</div>;
  }

  if (!post) {
    return null;
  }
  const createUtmLink = (path: string) => {
    return `${path}?utm_source=blog&utm_medium=article&utm_campaign=okr_insights`;
  };
  
  
  return (
    <div className="tw-max-w-4xl tw-mx-auto tw-px-4 tw-py-12">
      <article>
        <h1 className="tw-text-4xl tw-font-bold tw-text-gray-900 tw-mb-6">
          {post.title}
        </h1>
        
        <div className="tw-flex tw-items-center tw-text-sm tw-text-gray-500 tw-mb-8">
          {post.author && (
            <span className="tw-mr-4">By {post.author}</span>
          )}
          <time dateTime={post.published_date || post.created_at}>
            {new Date(post.published_date || post.created_at).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
            })}
          </time>
        </div>

        {post.featured_image && (
          <div className="tw-mb-8">
            <img 
              src={post.featured_image} 
              alt={post.title} 
              className="tw-w-full tw-h-auto tw-rounded-lg tw-shadow-md"
            />
          </div>
        )}

        <div 
          className="tw-prose tw-prose-indigo tw-max-w-none"
          dangerouslySetInnerHTML={{ __html: post.content }}
        />

<div className="tw-mt-12 tw-border-t tw-border-gray-200 tw-pt-8">
        <div className="tw-bg-indigo-50 tw-rounded-lg tw-p-6">
          <h3 className="tw-text-xl tw-font-semibold tw-text-gray-900 tw-mb-2">
            Ready to implement OKRs in your organization?
          </h3>
          <p className="tw-text-gray-600 tw-mb-4">
            OnTrakk helps you set, track, and achieve your objectives without the organizational burden.
          </p>
          <a 
            href={createUtmLink('/')}
            className="tw-inline-flex tw-items-center tw-bg-indigo-600 tw-text-white tw-px-4 tw-py-2 tw-rounded hover:tw-bg-indigo-700 tw-transition-colors"
            onClick={() => {
              ReactGA.event({
                category: "Conversion",
                action: "CTA Click",
                label: `From Blog: ${post.title}`
              });
            }}
          >
            Get Started with OnTrakk
            <svg className="tw-ml-2 tw-h-4 tw-w-4" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
            </svg>
          </a>
        </div>
      </div>
      </article>
    </div>
  );
};

export default BlogPost;

