import React from 'react';
import { format, parseISO } from 'date-fns';
import { Card, CardContent, Typography, Box } from '@mui/material';
import colors from '../components/color';
import DOMPurify from 'dompurify';

interface Comment {
  text: string;
  author: string;
  source: 'Linear' | 'OnTrakk';
  timestamp: string;
}

interface OKR {
  _id: string;
  Goal: string;
  TimePeriod?: string;
  comments?: Comment[]; 
  Status: string;
}

interface MyOKRTileProps {
  okr: OKR;
  onClick: () => void;
}

const MyOKRTile: React.FC<MyOKRTileProps> = ({ okr, onClick }) => {
  const formatDate = (dateString: string): string => {
    const date = parseISO(dateString);
    return format(date, 'd-MMM-yyyy');
  };

  const getStatusColor = () => {
    switch (okr.Status) {
      case 'Green':
        return colors.Green;
      case 'Amber':
        return colors.Amber;
      case 'Red':
        return colors.Red;
      default:
        return 'inherit';
    }
  };

  return (
    <Card
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        height: 'auto',
        minHeight: '140px',
        display: 'flex',
        flexDirection: 'column',
        borderLeft: `4px solid ${getStatusColor()}`,
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: 3,
        }
      }}
    >
      <CardContent
        sx={{
          p: 2,
          '&:last-child': { pb: 2 },
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 'medium',
            fontSize: '0.95rem',
            lineHeight: 1.3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            mb: 2
          }}
        >
          {okr.Goal}
        </Typography>

        <Box
          sx={{
            mt: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: 1
          }}
        >
          {okr.TimePeriod && (
            <Typography
              variant="body2"
              sx={{
                color: 'text.secondary',
                fontSize: '0.875rem',
                display: 'flex',
                alignItems: 'center',
                gap: 0.5
              }}
            >
              <span style={{ fontWeight: '500' }}>Due:</span> {formatDate(okr.TimePeriod)}
            </Typography>
          )}

          {okr.comments && okr.comments.length > 0 && (() => {
            // Sort by newest first
            const sortedComments = [...okr.comments].sort(
              (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
            );
            const newest = sortedComments[0];

            return (
              <Box
              sx={{
                fontSize: '0.875rem',
                color: 'text.secondary',
                mt: 1,
                fontStyle: 'italic',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                '& p': { m: 0 },
                '& ul, & ol': { pl: 2, mb: 0 },
                '& ul': { listStyleType: 'disc' },
                '& ol': { listStyleType: 'decimal' },
                '& li': { fontSize: '0.875rem', listStylePosition: 'outside' },
                '& strong': { fontWeight: 600 },
              }}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(newest.text) }}
            />
            );
          })()}
        </Box>
      </CardContent>
    </Card>
  );
};

export default MyOKRTile;
